import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Stack, Text } from '@atlaskit/primitives';

import { useGlobalNavigationActions, useGlobalNavigationState } from './GlobalNavigationStore';

export const CustomizeSidebarDialog = () => {
	const intl = useIntl();
	const { isCustomizeSidebarDialogVisible } = useGlobalNavigationState();
	const { closeCustomizeSidebarDialog } = useGlobalNavigationActions();

	return (
		<ModalTransition>
			{isCustomizeSidebarDialogVisible && (
				<Modal>
					<ModalHeader>
						<Heading size="medium">{intl.formatMessage(i18n.customizeSidebarTitle)}</Heading>
					</ModalHeader>
					<ModalBody>
						<Stack space="space.150">
							<Text as="p" color="color.text.subtlest">
								{intl.formatMessage(i18n.customizeSidebarSubtitle)}
							</Text>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button onClick={closeCustomizeSidebarDialog}>
							{intl.formatMessage(i18n.customizeSidebarCancel)}
						</Button>
						<Button appearance="primary" type="submit">
							{intl.formatMessage(i18n.customizeSidebarSave)}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	);
};

const i18n = defineMessages({
	customizeSidebarTitle: {
		id: 'side-navigation.global-navigation.customize-sidebar-dialog.title',
		defaultMessage: 'Customize sidebar',
		description: 'Title for the customize sidebar dialog',
	},
	customizeSidebarSubtitle: {
		id: 'side-navigation.global-navigation.customize-sidebar-dialog.subtitle',
		defaultMessage:
			'Show, hide, and reorder navigation items in your left sidebar. Only you’ll see these changes.',
		description: 'Subtitle for the customize sidebar dialog',
	},
	customizeSidebarCancel: {
		id: 'side-navigation.global-navigation.customize-sidebar-dialog.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button label for the customize sidebar dialog',
	},
	customizeSidebarSave: {
		id: 'side-navigation.global-navigation.customize-sidebar-dialog.save',
		defaultMessage: 'Save',
		description: 'Save button label for the customize sidebar dialog',
	},
});
