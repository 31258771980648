import { createStore, createActionsHook, createStateHook } from 'react-sweet-state';
import type { Action } from 'react-sweet-state';

type GlobalNavigationState = {
	isCustomizeSidebarDialogVisible: boolean;
};

type GlobalNavigationActions = {
	openCustomizeSidebarDialog: () => Action<GlobalNavigationState>;
	closeCustomizeSidebarDialog: () => Action<GlobalNavigationState>;
};

const initialState: GlobalNavigationState = {
	isCustomizeSidebarDialogVisible: false,
};

export const actions: GlobalNavigationActions = {
	openCustomizeSidebarDialog:
		() =>
		({ setState }) => {
			setState({ isCustomizeSidebarDialogVisible: true });
		},
	closeCustomizeSidebarDialog:
		() =>
		({ setState }) => {
			setState({ isCustomizeSidebarDialogVisible: false });
		},
};

export const GlobalNavigationStore = createStore<GlobalNavigationState, GlobalNavigationActions>({
	initialState,
	actions,
	name: 'globalNavigationStore',
});

export const useGlobalNavigationActions = createActionsHook(GlobalNavigationStore);
export const useGlobalNavigationState = createStateHook(GlobalNavigationStore);
